import gql from "graphql-tag";
<template lang="pug">
    .content__main
        loading.social-loading
</template>
<script>
import gql from "graphql-tag";
import connectSocialMediaQuery from "@/graphql/queries/connectSocialMedia.query.graphql";

export default {
    computed: {
        type: (state) => state.$route.params.type,
        client: (state) => state.$route.params.client,
    },
    async mounted() {
        if (this.type === "start") {
            this.getSocialLink();
            return;
        }

        if (this.type === "check" && this.client) {
            let state = this.$route.query.state,
                code = this.$route.query.code;

            if (!state || !code) {
                this.$router.push(this.$router.resolve("/404").href);
                return false;
            }
            window.close();
        }
    },
    methods: {
        async getSocialLink() {
            await this.$apollo
                .query({
                    query: gql`
                        ${connectSocialMediaQuery}
                    `,
                    variables: {
                        client: this.client,
                        redirect_uri: `${window.location.origin}/connect/${this.client}/check`, // window.location.origin
                    },
                })
                .then((r) => {
                    const URL = r.data.connectSocialMedia.redirect_uri;
                    if (URL) {
                        window.location.href = URL;
                    }
                });
        },
    },
};
</script>

<style>
.social-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000000;
}
</style>
